import { graphql, Link as LinkComponent } from "gatsby";
import * as React from "react";
import { Article as ArticleComponent } from "@monemprunt/design-system";

// components
import Layout from "../components/layout";
import Seo from "../components/seo";

// types & interfaces
import type { PageProps, SEO } from "../lib/types";

interface MentionLegalesPageProps {
  title: string;
  SEO: SEO;
  content: string;
}

const PageMentionLegales: React.FC<PageProps<MentionLegalesPageProps>> = ({
  data: {
    page: { SEO, title, content },
  },
  location: { pathname },
}) => {
  // const renderAst = transformHTMLBodyToReactNodes(htmlAst, `/mentions-legales`);
  return (
    <>
      <Layout>
        <Seo {...SEO} pathname={pathname} />
        <main className="mt-40 mb-52 flex flex-col items-center space-y-8 px-5 md:px-15">
          <h1 className="text-3xl font-bold text-center">{title}</h1>

          <ArticleComponent
            content={content}
            className="max-w-[1200px]"
            customLink={props => <LinkComponent {...props} />}
          />
        </main>
      </Layout>
    </>
  );
};

export default PageMentionLegales;

export const query = graphql`
  query pageMentionLegales {
    page: strapiPageMentionsLegales {
      title
      content
      SEO {
        description
        slug
        title
      }
    }
  }
`;
